import React, { useState, useEffect } from "react";
import "./App.css";
import MapComponent from "./components/MainComponent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ViewDetails from "./components/ViewDetails";
import AdminHome from "./Admin/AdminAuth";

const App = () => {
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    fetch("https://csomagpontok.info/Admin/public/api/carriers")
      .then((response) => response.json())
      .then((data) => setCarriers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    console.log(carriers);
  }, [carriers]);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<MapComponent carriers={carriers} />} />
          <Route
            path="/location/:carrier_name/:name/:id"
            element={<ViewDetails />}
          />{" "}
          <Route path="/Admin" element={<AdminHome />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
