import React, { useState, useEffect } from "react";
import "firebase/auth";
import CreateUserForm from "./CreateUserForm";
import CreateAdmin from "./CreateAdmin";
import { auth } from "../UserConfig";
const AdminAuth = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup function
    return () => unsubscribe();
  }, []);

  return <div>{user ? <CreateUserForm /> : <CreateAdmin />}</div>;
};

export default AdminAuth;
