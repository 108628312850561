import React, { useEffect, useRef, useState } from "react";
import Corriers from "./SubPages/Corriers";
import InfoCoriers from "./SubPages/InfoCoriers";
import SearchInput from "./Blocks/SearchInput";
import "./style.css";
import jsonData from "./SubPages/foxpost-148d686dcafdfa5525b5c256df0.json";
import RippleCircleIcon from "./RippleCircleIcon";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import MapComponent from "./SubPages/MyMapComponent";
const MainComponent = ({ carriers }) => {
  const [isLoading, setisLoading] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [carrierJsonData, setCarrierJsonData] = useState([]);
  const [fiterWith, setFiterWith] = useState("");

  const [checkedCompanies, setCheckedCompanies] = useState([]);

  const handleCompanyCheck = (companyId, isChecked) => {
    setisLoading(true);
    console.log(checkedCompanies);
    setCheckedCompanies((prev) => {
      if (isChecked) {
        if (!prev.includes(companyId)) {
          setisLoading(false);
          return [...prev, companyId];
        }
      } else {
        if (prev.includes(companyId)) {
          setisLoading(false);
          return prev.filter((id) => id !== companyId);
        }
      }
      // Return the previous state if no changes are made
      return prev;
    });
  };

  const fetchJsonData = async () => {
    setisLoading(true);
    try {
      const jsonDataArray = [];
      for (const carrier of carriers) {
        const response = await axios.get(
          `https://csomagpontok.info/Admin/public/api/FetchJsonFile/${carrier.jsonUrl}`
        );
        const jsonData = response.data.map((data) => ({
          ...data,
          carrierId: carrier.id,
          jsonLogoUrl: carrier.LogoUrl, // Add carrierI
        }));
        jsonDataArray.push(...jsonData); // Spread the JSON data into the array
      }
      setCarrierJsonData(jsonDataArray);
      console.log(carrierJsonData);
      setisLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchJsonData();
  }, [carriers]);
  useEffect(() => {
    // Extracting the carrier ids and converting them to strings
    const idsAsString = carrierJsonData.map(
      (courier) => `${courier.carrierId}`
    );
    // Setting the checked companies state
    setCheckedCompanies(idsAsString);
  }, [carrierJsonData]);
  useEffect(() => {
    const filteredData = carrierJsonData.filter((courier) =>
      checkedCompanies.includes(courier.carrierId.toString())
    );
    setfilteredData(filteredData);
  }, [checkedCompanies, fiterWith]);

  const [isOpenMenu, setOpenMenu] = useState(0);

  const handleFilter = () => {
    console.log("clicked");
    if (isOpenMenu !== 0) {
      setOpenMenu(0);
      console.log(isOpenMenu);
    } else {
      setOpenMenu(1);
      console.log(isOpenMenu);
    }
  };

  const FilterWithSearch = (e) => {
    setisLoading(true);
    const filteredData = carrierJsonData.filter(
      (courier) =>
        courier.name.includes(e) ||
        courier.zip.includes(e) ||
        courier.addr.includes(e) ||
        courier.city.includes(e)
    );
    setfilteredData(filteredData);
    setisLoading(false);
  };

  return (
    <div
      className="container"
      style={{ margin: "0px 0px", padding: "0px 0px" }}
    >
      <div id="myWholeContentDiv">
        <div className="col-lg-6 col-md-12" id="leftSider">
          <div id="myHeader">
            <img className="MyTopLogo" src="/images/logo.svg" alt="loading" />{" "}
            <div className="searbox">
              <div style={{ display: "flex" }}>
                <SearchInput
                  setisLoading={setisLoading}
                  FilterWithSearch={FilterWithSearch}
                  style={{ width: "100%" }}
                />

                <div id="myFilterIcon" class="position-relative">
                  <svg
                    onClick={handleFilter}
                    style={{
                      color: isOpenMenu === 0 ? "lightgreen" : "black",
                      margin: "auto",
                      cursor: "pointer",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-filter-square-fill mx-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1M4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                  </svg>
                  <span
                    style={{
                      width: "30px",
                      height: "15px",
                      borderRadius: "50%",
                      border: "1px solid",
                      marginLeft: "-12px",
                      fontSize: "7px",
                      backgroundColor: "lightgreen",
                      marginTop: "-2px",
                    }}
                    class="position-absolute top-0 start-100 translate-middle badge "
                  >
                    <span>{checkedCompanies.length - 1}+</span>{" "}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="leftInner">
            <div style={{ display: isOpenMenu === 0 ? "" : "none" }}>
              <Corriers
                carriers={carriers}
                onCompanyCheck={handleCompanyCheck}
              />
            </div>
            <div style={{ float: "left" }}>
              <InfoCoriers
                isLoading={isLoading}
                carriers={carriers}
                checkedCompanies={checkedCompanies}
                filteredData={filteredData}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-15" id="LaptopMap">
          <MapComponent data={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
