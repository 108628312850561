import React from "react";
import ReactDOMServer from "react-dom/server";
import RippleCircle from "./RippleCircle";
import L from "leaflet";
const RippleCircleIcon = (props) => {
  const { digit } = props;
  const htmlString = ReactDOMServer.renderToString(
    <div className="ripple-circle-icon">
      <RippleCircle digit={digit} />
    </div>
  );
  return L.divIcon({
    html: htmlString,
    iconSize: [32, 32],
    className: "custom-marker",
  });
};

export default RippleCircleIcon;
