import React, { useState, useEffect } from "react";
import "./MyStyle.css";
import axios from "axios";

const Corriers = ({ onCompanyCheck, carriers }) => {
  const [jsonLengths, setJsonLengths] = useState({});

  const handleCompanyCheckboxChange = (companyId, isChecked) => {
    onCompanyCheck(companyId, isChecked);
    console.log(companyId);
  };

  useEffect(() => {
    const fetchAllJsonData = async () => {
      try {
        for (const carrier of carriers) {
          const response = await axios.get(
            `https://csomagpontok.info/Admin/public/api/FetchJsonFile/${carrier.jsonUrl}`
          );
          const jsonData = response.data;
          setJsonLengths((prevJsonLengths) => ({
            ...prevJsonLengths,
            [carrier.id]: jsonData.length,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllJsonData();
  }, [carriers]); // Run only when 'carriers' prop changes

  return (
    <div>
      <div id="myAllCorrirerCards">
        {carriers.map((carrier, index) => {
          return (
            <div id="smallCorrrierCards" key={index}>
              <input
                style={{ backgroundColor: "green" }}
                type="checkbox"
                className="mx-2"
                defaultChecked={true} // Set all checkboxes checked by default
                onChange={(e) =>
                  handleCompanyCheckboxChange(e.target.value, e.target.checked)
                }
                value={carrier.id}
              />
              <img
                src={`https://csomagpontok.info/Admin/public/logos/${carrier.LogoUrl}`}
                className="mx-2"
              />
              <div id="CorrierDetails" className="mx-2">
                <h5>{carrier.name}</h5>
                <p>{jsonLengths[carrier.id]} locations</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Corriers;
