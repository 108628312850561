import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./style.css";
import jsonData from "./SubPages/foxpost-148d686dcafdfa5525b5c256df0.json";
import { Helmet } from "react-helmet";
import RippleCircleIcon from "./RippleCircleIcon";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
function ViewDetails() {
  const { id } = useParams();

  const companyNames = [
    {
      id: 1,
      name: "FoxPost",
      TotalLocation: "182",
      CompanyLogo: "/images/icon-foxpost.svg",
    },
    {
      id: 2,
      name: "Packeta Z-Box",
      TotalLocation: "182",
      CompanyLogo: "/images/icon-gls-shop.svg",
    },
    {
      id: 3,
      name: "Packeta Z-Point",
      TotalLocation: "143",
      CompanyLogo:
        "https://cdn-icons-png.freepik.com/256/6642/6642007.png?semt=ais_hybrid",
    },
    {
      id: 4,
      name: "GLS Parcel Point",
      TotalLocation: "132",
      CompanyLogo:
        "https://cdn-icons-png.freepik.com/256/7920/7920867.png?semt=ais_hybrid",
    },
    {
      id: 5,
      name: "GLS Parcel Lockers",
      TotalLocation: "766",
      CompanyLogo:
        "https://cdn-icons-png.freepik.com/256/10952/10952728.png?semt=ais_hybrid",
    },
  ];
  const mapRef = useRef(null);
  useEffect(() => {
    if (!mapRef.current) {
      const mapInstance = L.map("map", {
        center: [47.5075, 19.0295],
        zoom: 7,
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(mapInstance);

      mapRef.current = mapInstance;
    }

    return () => {
      // Clean up map instance on unmount
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);
  const filteredData = jsonData.filter((courier) =>
    id.includes(String(courier.id))
  );
  useEffect(() => {
    const map = mapRef.current;
    if (map && filteredData && filteredData.length > 0) {
      map.eachLayer((layer) => {
        if (!layer._url) {
          map.removeLayer(layer);
        }
      });
      const { lat, lon } = filteredData[0];
      map.setView([parseFloat(lat), parseFloat(lon)], 10);
      filteredData.forEach((point) => {
        const { lat, lon, name, addr, comid, id } = point;
        const company = companyNames.find((company) => company.id === comid);
        const markerIcon = RippleCircleIcon({
          digit: company ? company.CompanyLogo : comid,
        }); // Use custom icon
        const marker = L.marker([parseFloat(lat), parseFloat(lon)], {
          icon: markerIcon,
        }).addTo(map);
        marker.bindPopup(`<div><b>${name}</b><br>${addr}</div>`).openPopup();
      });
    }
  }, [filteredData]);

  return (
    <div
      className="container"
      style={{ margin: "0px 0px", padding: "0px 0px" }}
    >
      {" "}
      <div id="myWholeContentDiv">
        <div className="col-lg-6 col-md-12" id="leftSiderViewEdails">
          {filteredData.map((i) => {
            return (
              <>
                <h3
                  style={{
                    fontFamily: "serif",
                    fontStyle: "italic",
                    marginTop: "30px ",
                    marginLeft: "10px",
                  }}
                >
                  {i.name}
                </h3>

                {companyNames
                  .filter((j) => j.id === i.comid)
                  .map((e, i) => {
                    return (
                      <>
                        <div
                          id="smallCorrrierCards"
                          key={i}
                          style={{ marginTop: "-16px" }}
                        >
                          <img src={e.CompanyLogo} className="mx-2" />
                          <div id="CorrierDetails" className="mx-2">
                            <h5>{e.name}</h5>
                            <p>{e.TotalLocation} locations</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                <div
                  style={{
                    margin: "0px 20px ",
                  }}
                >
                  <h4
                    style={{
                      fontFamily: "serif",
                      fontStyle: "italic",
                    }}
                  >
                    Carrier
                  </h4>
                  <h4 style={{ fontFamily: "sans-serif" }}>General Details:</h4>
                  <p>
                    city: {i.city},Postcode: {i.zip},address: {i.addr}
                  </p>
                  <h4 style={{ fontFamily: "sans-serif" }}>Description:</h4>
                  <p>{i.comment}</p>
                  <h4 style={{ fontFamily: "sans-serif" }}>Location Info:</h4>
                  <p>
                    Longitude:{i.lon}|Latitude:{i.lat}
                  </p>
                  <h4 style={{ fontFamily: "sans-serif" }}>Opening Hours:</h4>
                  <ul>
                    {typeof i.hours === "object" ? (
                      Object.entries(i.hours).map(([day, time]) => (
                        <li key={day}>{`Day ${day}: ${time}`}</li>
                      ))
                    ) : (
                      <li>{i.hours}</li>
                    )}
                  </ul>
                </div>
              </>
            );
          })}
        </div>
        <div className="col-lg-6 col-md-15" id="LaptopMap">
          {" "}
          <Link
            className="fixed-top"
            to={"/"}
            style={{ textDecoration: "none", color: "black", float: "right" }}
          >
            Back
          </Link>{" "}
          <div className="myMapContainer" id="map-container">
            <div id="map" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewDetails;
