import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDvcRa9Liob2jqJuncq6_Sxsnbx_T8ASV4",
  authDomain: "fiverproject-1aad8.firebaseapp.com",
  projectId: "fiverproject-1aad8",
  storageBucket: "fiverproject-1aad8.appspot.com",
  messagingSenderId: "629692488227",
  appId: "1:629692488227:web:83e61d064317b1ad232f96",
  measurementId: "G-CGXVXE5L6G",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const analytics = getAnalytics(app);
export { app, auth };
