import React, { useState } from "react";
import "./MyStyle.css";

const InfoCoriers = ({ isLoading, filteredData, carriers }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div id="MyTopInforCorrierDevForContents">
      <div id="myAllCorrirerCards">
        {isLoading && (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">.</span>
          </div>
        )}
        {filteredData.map((courier, index) => {
          return (
            <div
              id="smallCorrrierCards"
              key={index}
              onClick={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                backgroundColor:
                  hoveredIndex === index ? "lightgray" : "transparent",
                height: "fit-content",
                cursor: "pointer",
              }}
            >
              {carriers
                .filter((i) => i.id === courier.carrierId)
                .map((i) => {
                  return (
                    <>
                      <img
                        alt="loading"
                        src={`https://csomagpontok.info/Admin/public/logos/${i.LogoUrl}`}
                        className="mx-2"
                        style={{
                          margin: hoveredIndex === index ? "10px 0px" : "auto",
                        }}
                      />
                    </>
                  );
                })}

              <div id="CorrierDetails" className="mx-2">
                <h5>{courier.name}</h5>
                <p>
                  {courier.zip},{courier.city},{courier.addr}
                </p>
                {hoveredIndex === index && (
                  <div>
                    <p>{courier.comment}</p>
                    <h6 style={{ cursor: "pointer" }} className="mx-2">
                      <a
                        target="_blank"
                        href={`https://csomagpontok.info/Admin/public/${courier.name.replace(
                          /\s+/g,
                          "-"
                        )}/${courier.name.replace(/\s+/g, "-")}/${
                          courier.carrierId
                        }/${courier.id}`}
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        View Details
                      </a>

                      <svg
                        style={{ color: "green" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                        />
                      </svg>
                    </h6>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InfoCoriers;
