import React, { useState } from "react";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";

function SearchInput({ FilterWithSearch, setisLoading }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setisLoading(true);
    setSearchQuery(e);
    if (searchQuery !== "") {
      FilterWithSearch(e);
    } else {
      console.log("Search is empty");
    }
  };

  const handleClear = () => {
    setSearchQuery("");
  };

  return (
    <div style={{ width: "100%" }}>
      <Input
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search for name, address, city, postcode"
        prefix={
          <SearchOutlined style={{ color: "lightgreen", cursor: "pointer" }} />
        }
        suffix={
          searchQuery && (
            <CloseCircleOutlined
              onClick={handleClear}
              style={{ color: "lightgreen", cursor: "pointer" }}
            />
          )
        }
      />
    </div>
  );
}

export default SearchInput;
