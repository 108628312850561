import React from "react";
import "./style.css"; // Make sure this path is correct and points to your CSS file

const RippleCircle = ({ digit }) => {
  return (
    <div className="ripple-circle-container">
      <div className="circle">
        <div className="ripple">
          <img
            style={{ height: "20px", width: "20px", zInedx: "999" }}
            src={`https://csomagpontok.info/Admin/public/logos/${digit}`}
            className="digit"
          />
        </div>
      </div>
    </div>
  );
};

export default RippleCircle;
