import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Input, Upload, Divider } from "antd";

const AdminHome = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    fetch("http://127.0.0.1:8000/api/carriers")
      .then((response) => response.json())
      .then((data) => setCarriers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    console.log(carriers);
  }, [carriers]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Carrier Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => <img src={logo} alt="logo" style={{ width: 30 }} />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "JSON File URL",
      dataIndex: "jsonUrl",
      key: "jsonUrl",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => (
        <Button
          style={{ backgroundColor: "#21c47cd9", color: "white" }}
          size="small"
        >
          Edit Logo
        </Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Carrier 1",
      logo: "https://cdn-icons-png.freepik.com/256/10952/10952728.png?semt=ais_hybrid",
      description: "Description 1",
      jsonUrl: "https://example.com/json2",
    },
    {
      key: "2",
      name: "Carrier 2",
      logo: "https://cdn-icons-png.freepik.com/256/7920/7920867.png?semt=ais_hybrid",
      description: "Description 2",
      jsonUrl: "https://example.com/json2",
    },
  ];

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div style={{ display: "flex", gap: "5px" }}>
          <img
            src="/images/logo.svg"
            alt="Website Logo"
            style={{ width: 150 }}
          />{" "}
          <h6 style={{ margin: "auto" }}>Admin</h6>
        </div>
        <div>
          <Button
            style={{ backgroundColor: "#21c47cd9", color: "white" }}
            onClick={showModal}
          >
            Add Carrier
          </Button>
        </div>
      </div>
      <Table columns={columns} dataSource={carriers} />
      <Modal
        title="Add Carrier"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Submit
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Carrier Name">
            <Input />
          </Form.Item>
          <Form.Item label="Logo">
            <Upload>
              <Button>Upload Logo</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="JSON File URL">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <textarea style={{ height: "100px" }}></textarea>
        <textarea style={{ height: "100px" }}></textarea>
        <textarea style={{ height: "100px" }}></textarea>
        <textarea style={{ height: "100px" }}></textarea>
      </div>{" "}
      <Button
        className="my-3"
        style={{ backgroundColor: "#21c47cd9", color: "white" }}
      >
        Save
      </Button>
      <Divider /> <label>Website Logo</label>
      <input type="file" label="Add Logo"></input> <Divider />
    </div>
  );
};

export default AdminHome;
