import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../style.css";
import RippleCircleIcon from "../RippleCircleIcon";
const MyMapComponent = ({ data }) => {
  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  useEffect(() => {
    if (!mapRef.current && userLocation) {
      const mapInstance = L.map("map", {
        center: userLocation,
        zoom: 7,
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "© OpenStreetMap contributors",
      }).addTo(mapInstance);

      mapRef.current = mapInstance;
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [userLocation]);

  useEffect(() => {
    const map = mapRef.current;
    if (map && data && data.length > 0) {
      map.eachLayer((layer) => {
        if (!layer._url) {
          map.removeLayer(layer);
        }
      });

      data.forEach((point) => {
        const { lat, lon, name, addr, jsonLogoUrl, id, carrierId } = point;
        const markerIcon = RippleCircleIcon({
          digit: jsonLogoUrl,
        }); // Use custom icon
        const marker = L.marker([parseFloat(lat), parseFloat(lon)], {
          icon: markerIcon,
        }).addTo(map);
        marker
          .bindPopup(
            `<div><b>${name}</b><br>${addr}</div><div><a target="_blank" href='https://csomagpontok.info/Admin/public/location/${name.replace(
              /\s+/g,
              "-"
            )}/${addr.replace(
              /\s+/g,
              "-"
            )}/${carrierId}/${id}' style={{ textDecoration: "underlined", cursor: "pointer" }}>View </a></div>`
          )
          .openPopup();
      });
    }
  }, [data]);

  return (
    <div className="myMapContainer" id="map-container">
      <div id="map" />
    </div>
  );
};

export default MyMapComponent;
